import { CheckCircleFilled } from '@ant-design/icons'
import { Alert, Button, Card, Checkbox, Divider, Flex, Form, Input, Space, Typography } from 'antd'
import { BrandData, InviteToBrandNotification, Team, UserData } from '../../../types'
import useTeamNotifications from '../../../hooks/useTeamNotifications'
import { useBrandContext } from '../../../contexts/BrandContext'
import Spinner from '../../../components/Admin/utils/Spinner'
import { httpsCallable } from 'firebase/functions'
import LogRocket from 'logrocket'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReCaptcha } from 'next-recaptcha-v3'
import { CloudFunctions } from '../../../api/functions/types'
import { functions } from '../../../firebase_config/firebase_config'
import { useAuth } from '../contexts/AuthContext'
import { GIT_BRANCH } from '../../../data/gitBranch'

const { Title, Text } = Typography

export enum SignupIntent {
    JOIN_TEAM = 'join',
    CONTENT_CREATOR = 'talent',
}

export enum InviteType {
    TEAM = 'teamInvite',
    BRAND = 'brandInvite',
    DEFAULT = 'default',
}

const SignUpForm = () => {
    const { setRefetchIsNeeded } = useTeamNotifications()
    const { reloadBrands } = useBrandContext()
    const { currentUser, handleSignIn, handleSignOut, loadingAuth } = useAuth()
    const { executeRecaptcha } = useReCaptcha()

    const router = useRouter()
    const queryUid = router.query?.uid as string
    const queryTeamId = router.query?.team as string
    const queryBrandId = router.query?.brand as string
    const queryPriceNickname = (router.query?.priceNickname as string) || ''
    const queryCoupon = (router.query?.coupon as string) || ''
    const queryofferTeamEmail = router.query?.offerTeamEmail as string
    const specialOffer = (router.query?.specialOffer as string) || ''
    const customOffer = (router.query?.customOffer as string) || ''
    const queryIntent = (router.query?.intent as string) || ''
    const queryEmail = (router.query?.email as string) || ''
    const queryFirstName = (router.query?.firstname as string) || ''
    const queryLastName = (router.query?.lastname as string) || ''

    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [error, setError] = useState('')
    const [inviteStatus, setInviteStatus] = useState<CloudFunctions.Invite.GetBrandDataReturn['type'] | null>(null)
    const [user, setUser] = useState<UserData | null>(null)
    const [team, setTeam] = useState<Team | null>(null)
    const [brand, setBrand] = useState<BrandData | null>(null)
    const [notification, setNotification] = useState<InviteToBrandNotification | null>(null)

    const [form] = Form.useForm()

    // console.log('SignUpForm user', user)

    const signUpType = useMemo(() => {
        if (queryUid && queryTeamId && queryBrandId) {
            return InviteType.BRAND
        } else if (queryUid && queryTeamId) {
            return InviteType.TEAM
        } else {
            return InviteType.DEFAULT
        }
    }, [queryBrandId, queryTeamId, queryUid])

    useEffect(() => {
        if (queryofferTeamEmail) {
            form.setFieldsValue({ email: queryofferTeamEmail })
        } else if (queryEmail) {
            form.setFieldsValue({ email: queryEmail })
        }

        if (queryFirstName) {
            form.setFieldsValue({ firstName: queryFirstName })
        }

        if (queryLastName) {
            form.setFieldsValue({ lastName: queryLastName })
        }
    }, [queryofferTeamEmail, form, queryEmail, queryFirstName, queryLastName])

    useEffect(() => {
        const handleInvite = async () => {
            switch (signUpType) {
                case InviteType.BRAND:
                    const result = await CloudFunctions.Invite.getBrandData({
                        userId: queryUid,
                        teamId: queryTeamId,
                        brandId: queryBrandId,
                    })

                    setInviteStatus(result.data.type)

                    if (result.data.type === 'pending') {
                        setUser(result.data.user)
                        setTeam(result.data.team)
                        setBrand(result.data.brand)
                        setNotification(result.data.notification)
                    }

                    break
                case InviteType.TEAM: {
                    try {
                        const result = await CloudFunctions.Invite.getTeamData({
                            userId: queryUid,
                            teamId: queryTeamId,
                        })

                        setInviteStatus(result.data.type)

                        if (result.data.type === 'pending') {
                            setUser(result.data.user)
                            setTeam(result.data.team)
                        }
                    } catch (err) {
                        console.log(err)
                    }
                }
            }
        }

        if (signUpType !== 'default') {
            handleInvite()
        }
    }, [queryUid, queryTeamId, queryBrandId, signUpType])

    const authRedirect = useMemo(() => {
        if (signUpType === InviteType.BRAND) {
            return `/accept-brand-invite?uid=${queryUid}&team=${queryTeamId}&brand=${queryBrandId}`
        } else if (signUpType === InviteType.TEAM) {
            return `/accept-team-invite?uid=${queryUid}&team=${queryTeamId}`
        } else {
            return ''
        }
    }, [queryBrandId, queryTeamId, queryUid, signUpType])

    useEffect(() => {
        if (user && user.emailVerified === true && authRedirect) {
            router.push(`/login?authRedirect=${encodeURIComponent(authRedirect)}`)
        }
    }, [authRedirect, queryUid, router, user])

    useEffect(() => {
        if (form && user) {
            form.setFieldValue('email', user.email)
        }
    }, [router, user, form])

    const inviteError = useMemo<string | null>(() => {
        switch (inviteStatus) {
            case 'accepted':
                return 'Invite is already accepted'
            case 'invalid':
                return 'Invite is no longer available'
            default:
                return null
        }
    }, [inviteStatus])

    const handleSubmit = useCallback(
        async (values) => {
            setLoadingSubmit(true)
            setError('')

            if (currentUser) {
                await handleSignOut()
            }

            const { firstName, lastName, email, password } = values

            const displayName_ = `${firstName} ${lastName}`

            try {
                setError('')

                if (inviteStatus) {
                    const uid = router.query.uid as string

                    const res = await CloudFunctions.SignUp.finishExisting({
                        uid: uid,
                        password: password,
                        displayName: displayName_,
                    })

                    if (res.data?.emailVerified) {
                        if (signUpType === InviteType.BRAND) {
                            router
                                .push({
                                    pathname: '/accept-brand-invite',
                                    query: router.query,
                                })
                                .then(() => {
                                    handleSignIn(user.email, password)
                                })
                        } else if (signUpType === InviteType.TEAM) {
                            await router
                                .push({
                                    pathname: '/accept-team-invite',
                                    query: router.query,
                                })
                                .then(() => {
                                    handleSignIn(user.email, password)
                                })
                        }
                    } else {
                        await CloudFunctions.SignUp.sendVerificationEmail({ uid: uid, price: specialOffer as string })

                        let authRedirect = ''
                        if (signUpType === InviteType.BRAND) {
                            authRedirect = `/accept-brand-invite?uid=${uid}&team=${queryTeamId}&brand=${queryBrandId}`
                        } else if (signUpType === InviteType.TEAM) {
                            authRedirect = `/accept-team-invite?uid=${uid}&team=${queryTeamId}`
                        }

                        await router.push(`/finish-signup?uid=${uid}&authRedirect=${encodeURIComponent(authRedirect)}`)
                    }
                } else {
                    let priceNickname = queryPriceNickname
                    if (queryIntent === SignupIntent.JOIN_TEAM) {
                        priceNickname = 'trial'
                    }

                    const res = await CloudFunctions.SignUp.createUnverifiedUser({
                        displayName: displayName_,
                        email: email,
                        password: password,
                        priceNickname,
                        customOffer,
                        coupon: queryCoupon,
                        role: queryIntent === 'talent' ? 'talent' : '',
                    })

                    if (res.data.success) {
                        const uid = res.data.uid

                        LogRocket.identify(uid, { email: email })

                        await CloudFunctions.SignUp.sendVerificationEmail({ uid, price: specialOffer as string })

                        await router.push({ pathname: '/finish-signup', query: { ...router.query, uid } })
                    } else if (res.data.error && queryIntent === SignupIntent.JOIN_TEAM) {
                        const uid = res.data.uid

                        LogRocket.identify(uid, { email: email })

                        const finishRes = await CloudFunctions.SignUp.finishExisting({
                            uid: uid,
                            password: password,
                            displayName: displayName_,
                        })

                        if (!finishRes.data?.emailVerified) {
                            await CloudFunctions.SignUp.sendVerificationEmail({
                                uid: uid,
                                price: specialOffer as string,
                            })

                            await router.push({
                                pathname: '/finish-signup',
                                query: {
                                    ...router.query,
                                    uid,
                                    authRedirect: encodeURIComponent('/accept-team-invite'),
                                },
                            })
                        } else {
                            setError('Email already in use')
                            setTimeout(() => {
                                router.push({
                                    pathname: '/login',
                                    query: { email, ...router.query },
                                })
                            }, 3000)
                        }
                    }
                }
            } catch (err) {
                if (
                    err.message.includes('The email address is already in use by another account') ||
                    err.message.includes('already exist')
                ) {
                    setError('Email already in use')
                    setTimeout(() => {
                        router.push({
                            pathname: '/login',
                            query: { email, ...router.query },
                        })
                    }, 3000)
                } else {
                    setError('Failed to create an account')
                    console.log(err)
                }
            }

            setLoadingSubmit(false)
        },
        [
            currentUser,
            handleSignIn,
            handleSignOut,
            inviteStatus,
            customOffer,
            specialOffer,
            router,
            signUpType,
            user,
            queryPriceNickname,
            queryIntent,
            queryBrandId,
            queryTeamId,
            queryCoupon,
        ]
    )

    const submitEnquiryForm = useCallback(
        async (gReCaptchaToken, values) => {
            setLoadingSubmit(true)

            const recaptchav3Inquiry = httpsCallable<{ gRecaptchaToken: string }, any>(functions, 'recaptchav3Inquiry')
            const isProd = ['main'].includes(GIT_BRANCH)

            try {
                const res = await recaptchav3Inquiry({ gRecaptchaToken: gReCaptchaToken })
                if (!isProd) {
                    handleSubmit(values)
                } else {
                    if (res.data?.status === 'success') {
                        handleSubmit(values)
                        console.log('recaptcha success')
                    } else {
                        setLoadingSubmit(false)
                        setError('Recaptcha failed')
                    }
                }
            } catch (err) {
                console.log(err)
            }
        },
        [handleSubmit]
    )

    const handleSubmitForm = useCallback(async () => {
        if (!executeRecaptcha) {
            return
        }

        form.setFieldsValue(
            Object.fromEntries(
                Object.entries(form.getFieldsValue()).map(([key, value]) => {
                    return [
                        key,
                        key !== 'password' && key !== 'passwordConfirm' && typeof value === 'string'
                            ? value.trim()
                            : value,
                    ]
                })
            )
        )

        try {
            await form.validateFields()
        } catch (err) {
            return
        }

        if (!form.getFieldValue('agreeToTerms')) {
            form.setFields([{ name: 'agreeToTerms', errors: ['You must agree to the terms of service to continue'] }])

            return
        }

        const updatedValues = form.getFieldsValue()

        setLoadingSubmit(true)

        executeRecaptcha('enquiryFormSubmit').then((gReCaptchaToken) => {
            console.log(gReCaptchaToken, 'response Google reCaptcha server')
            submitEnquiryForm(gReCaptchaToken, updatedValues)
        })
    }, [executeRecaptcha, form, submitEnquiryForm])

    const handleGoToLogin = async () => {
        await router.push('/login')
    }

    return inviteError ? (
        <Card className="w-100">
            <h6 data-test="invite-cancel">{inviteError}</h6>

            <Button className="d-block mt-3 ms-auto" type="primary" onClick={handleGoToLogin}>
                Go to login
            </Button>
        </Card>
    ) : (loadingAuth && !loadingSubmit) ||
      (signUpType === InviteType.BRAND && (!user || !brand || !team || !notification)) ||
      (signUpType === InviteType.TEAM && (!user || !team)) ? (
        <div className="d-flex justify-content-center align-items-center w-100">
            <Spinner size="large" />
        </div>
    ) : (
        <Flex className="w-100" gap="large" vertical>
            <Space direction="vertical" size="small">
                <Title level={1} style={{ marginBottom: '0' }}>
                    Get your account started
                </Title>
                <Text>You&apos;ll be inside and creating ads in 2 minutes!</Text>
            </Space>
            <Space direction="vertical" size="small">
                <Form form={form} onFinish={handleSubmitForm} layout="vertical" autoComplete="off">
                    <Flex className="w-100" gap="middle">
                        <Form.Item
                            className="w-100"
                            name="firstName"
                            hasFeedback
                            rules={[
                                { required: true, type: 'string', message: 'Please input your first name' },
                                {
                                    pattern: new RegExp('^[A-Za-z]+$'),
                                    message: 'Incorrect format',
                                },
                            ]}
                        >
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                            className="w-100"
                            name="lastName"
                            hasFeedback
                            rules={[
                                { required: true, type: 'string', message: 'Please input your last name' },
                                {
                                    pattern: new RegExp('^[A-Za-z]+$'),
                                    message: 'Incorrect format',
                                },
                            ]}
                        >
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </Flex>
                    <Form.Item
                        name="email"
                        hasFeedback
                        rules={[{ required: true, type: 'email', message: 'Please input your email' }]}
                    >
                        <Input
                            placeholder="Email"
                            disabled={!!team || !!queryofferTeamEmail}
                            suffix={
                                (!!team || !!queryofferTeamEmail) && (
                                    <CheckCircleFilled className="fs-6" style={{ color: '#52C41A' }} />
                                )
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        hasFeedback
                        rules={[
                            { required: true, type: 'string', message: 'Please input your password' },
                            () => ({
                                validator(_, value) {
                                    if (!value || value.length >= 6) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Password must be at least 6 characters long'))
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Password" autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                        name="passwordConfirm"
                        hasFeedback
                        rules={[
                            { required: true, type: 'string', message: 'Please confirm your password' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('The password that you entered does not match'))
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Confirm Password" autoComplete="new-password" />
                    </Form.Item>
                    <Form.Item
                        name="agreeToTerms"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                type: 'boolean',
                                message: 'You must agree to the terms of service to continue',
                            },
                        ]}
                    >
                        <Checkbox>
                            <Text>
                                I have read and agree to the{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://app.termly.io/document/terms-of-service/7fc5531c-2ae1-4501-b64f-1fd3926842ff"
                                >
                                    Terms of Service
                                </a>
                            </Text>
                        </Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            data-test="register-now"
                            className="w-100"
                            type="primary"
                            htmlType="submit"
                            loading={loadingSubmit}
                        >
                            Register Now
                        </Button>
                    </Form.Item>
                    {error && <Alert type="error" message={error} />}
                    <Divider plain>Already have an account?</Divider>
                    <Button
                        className="w-100"
                        type="default"
                        onClick={() => {
                            router.push({
                                pathname: '/login',
                                query: router.query,
                            })
                        }}
                    >
                        Sign In
                    </Button>
                </Form>
            </Space>
        </Flex>
    )
}

export default SignUpForm
